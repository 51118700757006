import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL
  ? process.env.REACT_APP_BACKEND_URL
  : "http://localhost:80";

export const mainApi = createApi({
  reducerPath: "mainApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({
    getPlatforms: builder.query({
      query: () => `/platforms`,
    }),
    getBranchesCommits: builder.query({
      query: () => `/branches_commits`,
    }),
    getDesigns: builder.query({
      query: (id) => `/platform?platform=${id}`,
      transformResponse: (response) => {
        return Object.keys(response["designs"]);
      },
      skip: ({ id }) => !id,
    }),
    getBranch: builder.query({
      query: (branchName) => `/branch?branchName=${branchName}`,
      skip: ({ branchName }) => !branchName,
    }),
    getCommit: builder.query({
      query: (commitSHA) => `/commit?commitSHA=${commitSHA}`,
      skip: ({ commitSHA }) => !commitSHA,
    }),
    getMetricTrends: builder.query({
      query: ({ branchName, platform, designs, variant }) =>
        `/metric_trends?platform=${platform}&designs=${designs}&variant=${variant}&branchName=${branchName}`,
      transformResponse: (response) => {
        const flattenedObj = {};
        Object.keys(response).forEach((key) => {
          flattenedObj[key] = response[key].flat(Infinity);
        });
        return flattenedObj;
      },
      skip: ({ branchName, platform, designs, variant }) =>
        !branchName ||
        !platform ||
        Object.key(designs).length === 0 ||
        !designs ||
        !variant,
    }),
    getCompare: builder.query({
      // query: ({
      //   sourceAName,
      //   sourceAType,
      //   sourceBName,
      //   sourceBType,
      //   platform,
      //   designs,
      //   variant,
      // }) =>
      //   `/compare?platform=${platform}&designs=${designs}&variant=${variant}&sourceA_name=${sourceAName}&sourceA_type=${sourceAType}&sourceB_name=${sourceBName}&sourceB_type=${sourceBType}`,
      query: (data) => ({
        url: "/compare", // Replace with your API endpoint
        method: "LIST",
        body: data,
      }),
      transformResponse: (response) => {
        const metrics = response?.metrics;
        metrics?.sort((a, b) => {
          const nameA = a?.metrics?.toUpperCase();
          const nameB = b?.metrics?.toUpperCase();

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          return 0;
        });
        return {
          compare: response?.compare,
          metrics: metrics,
        };
      },
      skip: ({
        sourceAName,
        sourceAType,
        sourceBName,
        sourceBType,
        platform,
        designs,
        variant,
      }) =>
        !sourceAName ||
        !sourceAType ||
        !sourceBName ||
        !sourceBType ||
        !platform ||
        !designs,
    }),
    getBranchLatestDesigns: builder.query({
      query: ({ branchName, platform, designs, variant }) =>
        `/branchlatest_designs?platform=${platform}&designs=${designs}&variant=${variant}&branchName=${branchName}`,
    }),
    getCommitDesigns: builder.query({
      query: ({ commitSHA, platform, designs, variant }) =>
        `/commit_designs?platform=${platform}&designs=${designs}&variant=${variant}&commitSHA=${commitSHA}`,
    }),
    getBranchLatest: builder.query({
      query: ({ branchName, platform, design, variant }) => ({
        url: "/branchlatest",
        method: "GET",
        params: { branchName, platform, design, variant },
      }),
    }),
    getCommitMetrics: builder.query({
      query: ({ commitSHA, platform, design, variant }) => ({
        url: "/commit",
        method: "GET",
        params: { commitSHA, platform, design, variant },
      }),
    }),
    getBranchCommitPlatforms: builder.query({
      query: ({ branchName, commitSHA }) => ({
        url: "/branch_platforms",
        method: "GET",
        params: { branchName, commitSHA },
      }),
    }),
    getSourceData: builder.query({
      query: ({ branchName, commitSHA }) => ({
        url: "/sourceData",
        method: "GET",
        params: { branchName, commitSHA },
      }),
    }),
    getPlatformsSummary: builder.query({
      query: (data) => ({
        url: "/platforms/summary", // Replace with your API endpoint
        method: "LIST",
        body: data,
      }),
    }),
    getBuildNumbers: builder.query({
      query: ({ branchName, commitSHA }) => ({
        url: "/build_numbers",
        method: "GET",
        params: { branchName, commitSHA },
      }),
    }),
    getDistData: builder.query({
      query: ({ cutOffValue, forceUpdate }) => ({
        url: "/dist",
        method: "GET",
        params: { cutOffValue, forceUpdate },
      }),
      transformResponse: (response) => {
        const data = response?.data;
        data?.sort((a, b) => {
          const nameA = a?.metric?.toUpperCase();
          const nameB = b?.metric?.toUpperCase();

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          return 0;
        });
        return {
          ...response,
          data: data,
        };
      },
    }),
    getBranchCommits: builder.query({
      query: ({ branchName }) => ({
        url: "/branch_commits",
        method: "GET",
        params: { branchName },
      }),
    }),
    getGoldenCommit: builder.query({
      query: () => ({
        url: "/golden",
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetDesignsQuery,
  useGetPlatformsQuery,
  useGetBranchLatestQuery,
  useLazyGetBranchLatestQuery,
  useGetMetricTrendsQuery,
  useLazyGetMetricTrendsQuery,
  useGetCommitMetricsQuery,
  useLazyGetCommitMetricsQuery,
  useGetBranchCommitPlatformsQuery,
  useGetBranchesCommitsQuery,
  useGetBranchLatestDesignsQuery,
  useGetCommitDesignsQuery,
  useGetCompareQuery,
  useGetBranchQuery,
  useGetCommitQuery,
  useGetSourceDataQuery,
  useGetPlatformsSummaryQuery,
  useGetBuildNumbersQuery,
  useGetDistDataQuery,
  useGetBranchCommitsQuery,
  useGetGoldenCommitQuery,
} = mainApi;
