import React, { useState, useEffect, useRef } from "react";
import { Table, Input, Button, Space, Collapse } from "antd";
import "./Grid.css";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { capitalizeFirstLetter } from "../../utils/util";
const { Column, ColumnGroup } = Table;
const { Panel } = Collapse;

const SummaryGrid = ({
  dataSource,
  selectedFilters,
  filterType,
  title = "Summary Table",
}) => {
  //              Metrics Search Filter  BEGIN         //
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
          marginRight: 7,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  //              Metrics Search Filter END           //

  const status = ["outlier", "normal", "none"];
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        marginBottom: "20px",
      }}
    >
      <Collapse defaultActiveKey={["1"]}>
        <Panel header={title} key="1">
          <Table
            dataSource={dataSource?.data?.filter((item) => {
              if (selectedFilters.length === 0) {
                return true;
              }
              return selectedFilters.includes(item[filterType]);
            })}
            pagination={false}
            bordered
            size="large"
            scroll={{
              // x: "calc(700px + 50%)",
              y: "40vw",
            }}
          >
            <Column
              title="Metrics"
              dataIndex="metric"
              key="metric"
              fixed="left"
              filterMode="tree"
              filterSearch={true}
              width={35}
              {...getColumnSearchProps("metric")}
            />
            {Object.keys(dataSource?.columns).map((platform, index) => (
              <ColumnGroup key={index} title={platform}>
                {status.map((design) => (
                  <Column
                    title={capitalizeFirstLetter(design)}
                    key={platform + "/" + design}
                    dataIndex={platform + "/" + design}
                    align="center"
                    width={11}
                    render={(text) => {
                      if (!text)
                        return (
                          <div key={index} style={{ textAlign: "center" }}>
                            0
                          </div>
                        );
                      else
                        return (
                          <div key={index} style={{ textAlign: "center" }}>
                            {text}
                          </div>
                        );
                    }}
                  />
                ))}
              </ColumnGroup>
            ))}
          </Table>
        </Panel>
      </Collapse>
    </div>
  );
};

export default SummaryGrid;
