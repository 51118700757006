import React, { useEffect, useState } from "react";
import { Select, Checkbox } from "antd";
import "./CustomSelect.css";
import { filterDesigns } from "../../utils/util";

const { Option } = Select;

const CustomSelect = ({
  options,
  onSelect,
  disabled,
  clear,
  onClear,
  selectDefault,
  isDesigns,
  defaultSelection,
  placeholder = "Please select",
}) => {
  const [selectedOptions, setSelectedOptions] = useState(
    selectDefault
      ? isDesigns
        ? filterDesigns(options, true).map((option) => option.value)
        : defaultSelection !== []
        ? defaultSelection
        : options.map((option) => option.value)
      : []
  );
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAll = (e) => {
    const { checked } = e.target;
    const allValues = options.map((option) => option.value);
    setSelectedOptions(checked ? allValues : []);
    onSelect(checked ? allValues : []);
    setSelectAll(checked);
  };

  const handleSelect = (value) => {
    setSelectedOptions(value);
    onSelect(value);
    setSelectAll(value.length === options.length);
  };

  useEffect(() => {
    if (clear) {
      setSelectedOptions([]);
      setSelectAll(false);
    }
  }, [clear]);

  useEffect(() => {
    if (defaultSelection) {
      setSelectedOptions(defaultSelection);
    }
  }, [defaultSelection]);

  const handleClear = () => {
    if (typeof onClear === "function") {
      setSelectedOptions([]);
      setSelectAll(false);
      onClear();
    }
  };

  return (
    <Select
      mode="multiple"
      onChange={handleSelect}
      value={selectedOptions}
      allowClear
      placeholder={placeholder}
      disabled={disabled}
      onClear={handleClear}
      className="custom-select"
    >
      {options.length !== 0 && (
        <Option key="select-all" value="select-all" disabled>
          <Checkbox checked={selectAll} onChange={handleSelectAll}>
            Select All
          </Checkbox>
        </Option>
      )}
      {options.map((option) => (
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
};

export default CustomSelect;
