import React from "react";
import { Table, Collapse } from "antd";

const { Column } = Table;

const PlatformsSummaryTable = ({
  data,
  platformUnion,
  variant,
  onCellClick,
  loading,
  title = "Summary Table",
}) => {
  return (
    <Table
      key="summary"
      dataSource={data}
      pagination={false}
      bordered
      size="large"
      scroll={{
        x: "calc(700px + 50%)",
        y: "40vw",
      }}
      loading={loading}
    >
      <Column
        title="Status"
        dataIndex="status"
        key="status"
        fixed="left"
        width={0}
      />
      {platformUnion?.map((platform, index) => (
        <Column
          width={0}
          title={platform}
          dataIndex={platform}
          key={platform}
          render={(text) => {
            return (
              <div key={index} style={{ textAlign: "center" }}>
                {text}
              </div>
            );
          }}
        />
      ))}
    </Table>
  );
};

export default PlatformsSummaryTable;
