import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./LandingPage.css";
import { Button, Divider, notification } from "antd";
import { Link, useLocation } from "react-router-dom";
import {
  TwitterOutlined,
  GithubOutlined,
  FileTextOutlined,
  MailOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { loginUser } from "../features/auth/authAction";
import { useNavigate } from "react-router-dom";

const LandingPage = () => {
  const { loading, userInfo, error, success } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();

  const login = useGoogleLogin({
    onSuccess: (credentialResponse) => {
      console.debug(credentialResponse);
      dispatch(loginUser(credentialResponse["access_token"]));
    },
    onError: () => {
      console.debug("Login Failed");
    },
  });

  const navigate = useNavigate();

  const showUnauthorizedNotification = () => {
    notification.error({
      message: "Unauthorized",
      description: "You are not authorized to perform this action.",
    });

    // Automatically close the notification after 3 seconds (3000 milliseconds)
    setTimeout(() => {
      notification.destroy(); // Close the notification
    }, 3000);
  };

  useEffect(() => {
    // redirect user to login page if registration was successful
    if (success) {
      if (userInfo?.role == "Admin") {
        // redirect authenticated user to profile screen
        navigate("/admin");
      } else {
        showUnauthorizedNotification();
      }
    }
  }, [navigate, userInfo, success]);

  const handleSuccess = (response) => {
    const idToken = response.tokenId;
  };
  return (
    <div className="background-image">
      <h1 style={{ textAlign: "center", marginTop: "150px" }}>
        OpenROAD QoR Dashboard
      </h1>
      <p
        style={{
          textAlign: "center",
          paddingLeft: "150px",
          paddingRight: "150px",
          marginBottom: "50px",
        }}
      >
        A comprehensive dashboard facilitating data-driven decision-making and
        performance optimization in the design flow. It is tailored for
        RTL-to-GDS flow stages, targeting different designs across various
        platforms.
      </p>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="button-bckg">
          <Button type="primary" onClick={() => login()}>
            Admin Login
          </Button>
          <p>
            Admin Functionality for the dashboard. Requires escalated access
          </p>
        </div>
        <Divider type="vertical" className="bold-divider" />
        <div className="button-bckg">
          <Button type="primary">
            <Link
              style={{ color: "inherit", textDecoration: "none" }}
              to="/trends"
            >
              Metrics Trends
            </Link>
          </Button>
          <p>Visualize metric trends for platforms and their designs</p>
        </div>
        <Divider type="vertical" className="bold-divider" />
        <div className="button-bckg">
          <Button type="primary">
            <Link
              style={{ color: "inherit", textDecoration: "none" }}
              to="/compare"
            >
              Source Comparison
            </Link>
          </Button>
          <p>
            Compare metrics between branches or commits for a specific platform
            and its designs
          </p>
        </div>
      </div>
      <div
        style={{
          marginTop: "50px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <a
          href="https://twitter.com/OpenROAD_EDA"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "black" }}
        >
          <TwitterOutlined
            style={{ marginRight: "20px", textDecoration: "none" }}
          />
        </a>
        <a
          href="https://github.com/The-OpenROAD-Project/OpenROAD-flow-scripts"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "black" }}
        >
          <GithubOutlined style={{ marginRight: "20px" }} />
        </a>
        <a
          href="https://openroad-flow-scripts.readthedocs.io/en/latest/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "black" }}
        >
          <FileTextOutlined style={{ marginRight: "20px" }} />
        </a>
        <a
          href="openroad@eng.ucsd.edu"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "black" }}
        >
          <MailOutlined style={{ marginRight: "20px" }} />
        </a>
        <a
          href="https://theopenroadproject.org/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "black" }}
        >
          <GlobalOutlined />
        </a>
      </div>
    </div>
  );
};

export default LandingPage;
