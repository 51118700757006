import React, { useState, useEffect, useRef } from "react";
import { Table, Input, Button, Space } from "antd";
import "./Grid.css";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
const { Column, ColumnGroup } = Table;

const GridTable = ({ dataSource, selectedFilters, filterType, designMap }) => {
  //              Metrics Search Filter  BEGIN         //
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
          marginRight: 7,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  //              Metrics Search Filter END           //

  console.log("columns", dataSource?.columns);

  return (
    <Table
      dataSource={dataSource?.data?.filter((item) => {
        if (selectedFilters.length === 0) {
          return true;
        }
        return selectedFilters.includes(item[filterType]);
      })}
      pagination={false}
      bordered
      size="large"
      scroll={{
        y: "40vw",
      }}
    >
      <Column
        title="Metrics"
        dataIndex="metric"
        key="metric"
        fixed="left"
        filterMode="tree"
        filterSearch={true}
        width={40}
        {...getColumnSearchProps("metric")}
      />
      {Object.keys(dataSource?.columns).map((platform, index) => (
        <ColumnGroup key={index} title={platform}>
          {dataSource?.columns[platform].map((design) => (
            <Column
              // ellipsis={{ showTitle: false }}
              title={() => (
                <span style={{ fontSize: "9px", fontWeight: "bold" }}>
                  {designMap[design]}
                </span>
              )}
              key={platform + "/" + design}
              dataIndex={platform + "/" + design}
              align="center"
              width={7}
              render={(record) => {
                const dist = platform + "/" + design;
                if (record === "outlier-low") {
                  return (
                    <div
                      className="cell"
                      style={{
                        background: "#7CB9E8",
                      }}
                    >
                      {"  "}
                    </div>
                  );
                } else if (record === "outlier-high") {
                  return (
                    <div
                      className="cell"
                      style={{
                        background: "orange",
                      }}
                    >
                      {"  "}
                    </div>
                  );
                } else if (record === "normal") {
                  return (
                    <div
                      className="cell"
                      style={{
                        background: "green",
                      }}
                    >
                      <span>{"   "}</span>
                    </div>
                  );
                } else if (record === "PR-outlier-low") {
                  return (
                    <div
                      className="cell"
                      style={{
                        background: "#00308F",
                      }}
                    >
                      <span>{"   "}</span>
                    </div>
                  );
                } else if (record === "PR-outlier-high") {
                  return (
                    <div
                      className="cell"
                      style={{
                        background: "red",
                      }}
                    >
                      <span>{"   "}</span>
                    </div>
                  );
                } else {
                  return <div style={{ textAlign: "center" }}>{"   "}</div>;
                }
              }}
            />
          ))}
        </ColumnGroup>
      ))}
    </Table>
  );
};

export default GridTable;
