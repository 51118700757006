export function createOptionsArray(stringsArray) {
  return stringsArray.map((str) => ({ value: str, label: str }));
}

export function createFiltersArray(stringsArray) {
  return stringsArray.map((str) => ({ text: str, valule: str }));
}

export function convertArrayToObject(array, key) {
  return array.map((item) => ({ [key]: item }));
}

export function convertDates(response) {
  const convertedResponse = {};

  Object.keys(response).forEach((key) => {
    convertedResponse[key] = response[key].map((obj) => ({
      ...obj,
      date: new Date(obj.date),
    }));
  });

  return convertedResponse;
}

export const transformObjectsToTable = (
  name1,
  name2,
  objDesigns1,
  objDesigns2
) => {
  console.debug(
    "params to transformed are",
    name1,
    name2,
    objDesigns1,
    objDesigns2
  );
  if (
    !name1 ||
    !name2 ||
    objDesigns1 === { "": null } ||
    objDesigns2 === { "": null } ||
    "error" in objDesigns1 ||
    "error" in objDesigns2
  )
    return [];
  const transformedDataObj = {};
  // loop on designs

  const designs1 = objDesigns1 ? Object.keys(objDesigns1) : [];
  const designs2 = objDesigns2 ? Object.keys(objDesigns2) : [];

  const allDesigns = new Set([...designs1, ...designs2]); // Combine keys and remove duplicates
  allDesigns?.forEach((design) => {
    const obj1 = design in objDesigns1 ? objDesigns1[design] : {};
    const obj2 = design in objDesigns2 ? objDesigns2[design] : {};
    const obj1list = obj1 ? Object.keys(obj1) : [];
    const obj2list = obj2 ? Object.keys(obj2) : [];

    const allMetrics = new Set([...obj1list, ...obj2list]); // Combine keys and remove duplicates
    allMetrics?.forEach((key) => {
      let newObj = {};
      newObj.metrics = key;
      if (key in transformedDataObj) {
        newObj = transformedDataObj[key];
      }
      // Assign each object name as the key to the value of the key in the previous object
      const key1 = design + "/" + name1;
      const key2 = design + "/" + name2;
      newObj[key1] = "";
      newObj[key2] = "";
      // Discard array values or nested objects
      if (typeof obj1 === "object" && key in obj1) {
        if (Array.isArray(obj1[key]) || typeof obj1[key] === "object") {
          return;
        }
        newObj[key1] = key in obj1 ? obj1[key] : "";
      }
      if (typeof obj2 === "object" && key in obj2) {
        if (Array.isArray(obj2[key]) || typeof obj2[key] === "object") {
          return;
        }
        newObj[key2] = key in obj2 ? obj2[key] : "";
      }

      transformedDataObj[key] = newObj;
    });
  });
  console.debug("transformed data obj", transformedDataObj);

  const transformedDataList = transformedDataObj
    ? Object.keys(transformedDataObj)
    : [];
  const transformedData = {};
  transformedData["metrics"] = transformedDataList;
  transformedData["data"] = [];
  transformedDataList?.forEach((metric) => {
    transformedData["data"].push(transformedDataObj[metric]);
  });

  return transformedData;
};

export function roundToFirstNonZero(number) {
  if (number && number !== Math.round(number)) {
    const str = number.toString();
    const match = str.match(/-?[1-9]+(\.[0-9]+)?/);
    // const match = str.match(/^\d+(\.\d+)?/);
    if (match) {
      const position = match.index + match[0].indexOf(".") + 1;
      const no = Number(number)?.toFixed(position);
      return Number(no).toFixed(2);
    }
  }
  return number;
}

export function operatorFromString(comparison) {
  switch (comparison) {
    case "==":
      return (a, b) => a == b;
    case "===":
      return (a, b) => a === b;
    case "!=":
      return (a, b) => a != b;
    case "!==":
      return (a, b) => a !== b;
    case ">":
      return (a, b) => a > b;
    case ">=":
      return (a, b) => a >= b;
    case "<":
      return (a, b) => a < b;
    case "<=":
      return (a, b) => a <= b;
    default:
      throw new Error(`Unknown comparison operator: ${comparison}`);
  }
}

export function filterDesigns(designs, isOptions) {
  const defaultDesigns = ["gcd", "aes", "ibex", "jpeg"];

  if (isOptions) {
    return designs.filter((item) => defaultDesigns.includes(item.value));
  }

  return designs.filter((item) => defaultDesigns.includes(item));
}

export function arrayFromObjects(arrayOfObjects, keyToExtract) {
  const valuesArray = [];
  arrayOfObjects.forEach((obj) => {
    // Check if the key exists in the current object
    if (keyToExtract in obj) {
      // Extract the value associated with the specified key
      const value = obj[keyToExtract];
      // Add the value to the valuesArray
      valuesArray.push(value);
    }
  });

  return valuesArray;
}

export function truncateString(str, maxLength) {
  if (str.length > maxLength) {
    return str.slice(0, maxLength); // Truncate to the first 6 characters
  }
  return str; // The string is already 6 characters or shorter
}

export function splitName(name) {
  if (name.includes(" ")) {
    const [first, last] = name.split(" ");
    return { first, last };
  } else {
    return { firt: name, last: "" };
  }
}

export function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function flattenObjectValuesToArray(obj) {
  return Object.values(obj);
}
