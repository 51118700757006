import React from "react";
import { format } from "date-fns";

const style = {
  padding: 6,
  backgroundColor: "#fff",
  border: "1px solid #ccc",
};

const CustomTooltip = (props) => {
  const { active, payload } = props;
  if (active && payload && payload.length) {
    console.debug("payload is", payload);
    const currData = payload && payload.length ? payload[0].payload : null;
    return (
      <div className="area-chart-tooltip" style={style}>
        <p>
          {"date : "}
          {currData ? format(new Date(currData.date), "yyyy-MM-dd") : " -- "}
        </p>
        {payload?.map((entry, index) => (
          <p key={index}>
            <em>{entry ? entry.dataKey : " -- "}</em>
            {": "}
            <em>{entry ? entry.value : " -- "}</em>
          </p>
        ))}
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
