import React from "react";
import { Table, Collapse } from "antd";
const { Panel } = Collapse;
const { Column } = Table;
const AbbreviationTable = ({ abbreviationData, designMap }) => {
  const columns = [
    {
      title: "Abbreviation",
      dataIndex: "abbreviation",
      key: "abbreviation",
    },
    {
      title: "Meaning",
      dataIndex: "meaning",
      key: "meaning",
    },
  ];

  const keysArray = Object.keys(designMap);
  const halfLength = Math.ceil(keysArray.length / 2);
  const firstHalfKeys = keysArray.slice(0, halfLength);

  const firstHalfMap = {};
  const secondHalfMap = {};

  keysArray.forEach((key) => {
    if (firstHalfKeys.includes(key)) {
      firstHalfMap[key] = designMap[key];
    } else {
      secondHalfMap[key] = designMap[key];
    }
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        marginBottom: "20px",
      }}
    >
      <Collapse defaultActiveKey={["1"]}>
        <Panel header={"Design Names Map"} key="1">
          <Table
            key="summary"
            dataSource={[firstHalfMap]}
            pagination={false}
            bordered
            size="large"
            scroll={{
              y: "40vw",
            }}
            style={{ marginBottom: "10px" }}
          >
            {Object.entries(firstHalfMap).map(([design, index]) => (
              <Column
                width={0}
                title={design}
                dataIndex={design}
                key={design}
                render={(text) => {
                  return (
                    <div key={index} style={{ textAlign: "center" }}>
                      {text}
                    </div>
                  );
                }}
              />
            ))}
          </Table>
          <Table
            key="summary"
            dataSource={[secondHalfMap]}
            pagination={false}
            bordered
            size="large"
            scroll={{
              // x: "calc(700px + 50%)",
              y: "40vw",
            }}
          >
            {Object.entries(secondHalfMap).map(([design, index]) => (
              <Column
                width={0}
                title={design}
                dataIndex={design}
                key={design}
                render={(text) => {
                  return (
                    <div key={index} style={{ textAlign: "center" }}>
                      {text}
                    </div>
                  );
                }}
              />
            ))}
          </Table>
        </Panel>
      </Collapse>
    </div>
  );
};

export default AbbreviationTable;
