import React, { useState } from "react";
import { Layout, Menu } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  BarChartOutlined,
  TableOutlined,
  LineChartOutlined,
  CalculatorOutlined,
  ToolOutlined,
  LoginOutlined,
} from "@ant-design/icons";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { loginUser } from "../../features/auth/authAction";
const { Header, Content, Sider } = Layout;

const CustomLayout = ({ children }) => {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const { loading, userInfo, error, success } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();

  const login = useGoogleLogin({
    onSuccess: (credentialResponse) => {
      console.debug(credentialResponse);
      dispatch(loginUser(credentialResponse["access_token"]));
    },
    onError: () => {
      console.debug("Login Failed");
    },
  });

  return (
    <Layout>
      <Header
        style={{
          backgroundColor: "	#E8E8E8",
          paddingInline: "15px",
          borderBottomColor: "black",
          borderBottom: "1px solid black",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>
            <BarChartOutlined
              style={{ fontSize: "30px", marginRight: "5px" }}
            />
            <span style={{ fontWeight: "bold", fontSize: "20px" }}>
              <Link style={{ color: "inherit", textDecoration: "none" }} to="/">
                OpenROAD Dashboard
              </Link>
            </span>
          </div>
          <div>
            <span style={{ fontSize: "20px" }}>
              Hello, {userInfo?.name ? userInfo?.name.split(" ")[0] : "Guest"}
            </span>
            {!userInfo?.name && (
              <LoginOutlined
                style={{
                  marginLeft: "10px",
                  marginRight: "10px",
                  color: "#52c41a",
                }}
                onClick={() => login()}
              />
            )}
          </div>
        </div>
      </Header>
      <Layout>
        <Sider
          width={200}
          style={{ backgroundColor: "black" }}
          collapsible
          collapsed={collapsed}
          onCollapse={toggleCollapse}
        >
          <Menu
            mode="inline"
            theme="dark"
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["sub1"]}
            style={{
              height: "100%",
              borderRight: 0,
              backgroundColor: "black",
              fontWeight: "bold",
            }}
            selectedKeys={[location.pathname]}
          >
            {userInfo?.role == "Admin" && (
              <Menu.Item key="/admin" icon={<ToolOutlined />}>
                <Link to="/admin">Admin</Link>
              </Menu.Item>
            )}
            <Menu.Item key="/trends" icon={<LineChartOutlined />}>
              <Link to="/trends">Metrics Trend</Link>
            </Menu.Item>
            <Menu.Item key="/compare" icon={<TableOutlined />}>
              <Link to="/compare">Compare</Link>
            </Menu.Item>
            <Menu.Item key="/significance" icon={<CalculatorOutlined />}>
              <Link to="/significance">Statistical Significance</Link>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout style={{ padding: "24px", backgroundColor: "white" }}>
          <Content
            className="site-layout-background"
            style={{
              padding: 0,
              margin: 0,
              minHeight: 280,
            }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default CustomLayout;
