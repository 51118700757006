import React from "react";
import { Table, Collapse } from "antd";

const { Column } = Table;
const { Panel } = Collapse;

const SummaryTable = ({
  data,
  selectedDesigns,
  variant,
  platform,
  onCellClick,
  loading,
  title = "Summary Table",
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        marginBottom: "20px",
      }}
    >
      <Collapse defaultActiveKey={["1"]}>
        <Panel header={title} key="1">
          <Table
            key="summary"
            dataSource={data}
            pagination={false}
            bordered
            size="large"
            scroll={{
              x: "calc(700px + 50%)",
              y: "40vw",
            }}
            loading={loading}
          >
            <Column
              title="Status"
              dataIndex="status"
              key="status"
              fixed="left"
              width={0}
            />
            {selectedDesigns?.map((design, index) => (
              <Column
                width={0}
                title={platform + " " + design + " " + variant}
                dataIndex={design}
                key={design}
                render={(text) => {
                  if (text != 0)
                    return (
                      <div key={index} style={{ textAlign: "center" }}>
                        <a>{text}</a>
                      </div>
                    );
                  else
                    return (
                      <div key={index} style={{ textAlign: "center" }}>
                        {text}
                      </div>
                    );
                }}
                onCell={(record) => {
                  return {
                    onClick: () => {
                      onCellClick(record, design);
                    },
                  };
                }}
              />
            ))}
          </Table>
        </Panel>
      </Collapse>
    </div>
  );
};

export default SummaryTable;
