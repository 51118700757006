import React from "react";
import "./ColorLegend.css"; // Import your CSS file for styling

const ColorLegend = ({ legendData }) => {
  return (
    <div className="color-legend">
      {legendData.map((item, index) => (
        <div key={index} className="legend-item">
          <div
            className="color-box"
            style={{ backgroundColor: item.color }}
          ></div>
          <div className="legend-label">{item.label}</div>
        </div>
      ))}
    </div>
  );
};

export default ColorLegend;
