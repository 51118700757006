import React, { useEffect, useState } from "react";
import "./MetricsTrendsPage.css";
import { useGetPlatformsQuery } from "../services/api";
import { Tabs } from "antd";
import PlatformTab from "../components/MetricTrends/PlatformTab";

const { TabPane } = Tabs;

const MetricsTrendsPage = ({ branchName = "master" }) => {
  const [selectedPlatform, setSelectedPlatform] = useState("asap7");
  const [filteredPlatforms, setFilteredPlatforms] = useState([]);

  const [loading, setLoading] = useState(false);

  const { data: platforms } = useGetPlatformsQuery();

  async function handleTabChange(tab) {
    setSelectedPlatform(tab);
  }

  const invisibleTabKeys = ["intel22", "intel16", "gf12", "gf55", "tsmc65lp"];
  useEffect(() => {
    setFilteredPlatforms(
      platforms?.filter((platform) => !invisibleTabKeys.includes(platform))
    );
  }, [platforms]);

  const [selectedMetrics, setselectedMetrics] = useState([]);
  const [defaultMetrics, setDefaultMetrics] = useState(true);
  const [clearMetrics, setClearMetrics] = useState(false);

  const handleMetricChange = (value) => {
    setDefaultMetrics(false);
    setselectedMetrics(value);
    setClearMetrics(false);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Tabs type="card" onChange={handleTabChange}>
        {filteredPlatforms?.map((tab) => (
          <TabPane tab={tab} key={tab}>
            <PlatformTab
              tab={tab}
              key={tab}
              branchName={branchName}
              selectedPlatform={selectedPlatform}
              handleMetricChange={handleMetricChange}
              setselectedMetrics={setselectedMetrics}
              selectedMetrics={selectedMetrics}
              defaultMetrics={defaultMetrics}
              setClearMetrics={setClearMetrics}
              clearMetrics={clearMetrics}
            />
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default MetricsTrendsPage;
