import React from "react";
import { Table, Collapse } from "antd";
import { roundToFirstNonZero } from "../../utils/util";

const { Column } = Table;

const PlatformsAverageTable = ({
  data,
  platformUnion,
  variant,
  onCellClick,
  loading,
  title = "Summary Table",
}) => {
  console.log("avg table data", data);
  return (
    <Table
      key="average"
      dataSource={data}
      pagination={false}
      bordered
      size="large"
      scroll={{
        x: "calc(700px + 50%)",
        y: "40vw",
      }}
      loading={loading}
    >
      <Column
        title="Metrics"
        dataIndex="metrics"
        key="metrics"
        fixed="left"
        width={0}
      />
      <Column
        title="Summary"
        dataIndex="summary"
        key="summary"
        fixed="left"
        width={0}
        render={(text) => {
          return (
            <div style={{ textAlign: "center" }}>
              {roundToFirstNonZero(text)}
            </div>
          );
        }}
      />
      {platformUnion?.map((platform, index) => (
        <Column
          width={0}
          title={platform}
          dataIndex={platform}
          key={platform}
          render={(text) => {
            return (
              <div key={index} style={{ textAlign: "center" }}>
                {roundToFirstNonZero(text)}
              </div>
            );
          }}
        />
      ))}
    </Table>
  );
};

export default PlatformsAverageTable;
