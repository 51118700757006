import React from "react";
import { Button } from "antd";
import Papa from "papaparse";
import { saveAs } from "file-saver";

const ExportButton = ({
  compareData,
  selectedFilters,
  filterType,
  selectedDesigns,
  value1,
  value2,
  buildId1,
  buildId2,
  style,
  filename = "export.csv",
}) => {
  const roundToFirstNonZero = (num) => {
    if (num === 0) return "0";
    const parts = num.toString().split(".");
    if (parts.length === 1) return parts[0];
    return `${parts[0]}.${parts[1].substring(0, 1)}`;
  };

  const generateIterRows = (
    metricBase,
    selectedDesigns,
    value1,
    value2,
    buildId1,
    buildId2,
    startIter
  ) => {
    const rows = [];
    for (let i = startIter + 1; i <= 64; i++) {
      const row = { Metrics: `${metricBase}__iter__${i}` };
      selectedDesigns.forEach((design) => {
        row[`${design} Base`] = "-";
        row[`${design} Test`] = "-";
        row[`${design} Comparison`] = "-";
      });
      rows.push(row);
    }
    return rows;
  };

  const exportToCSV = () => {
    const dataToExport = compareData?.metrics?.filter((item) => {
      if (selectedFilters.length === 0) {
        return true;
      }
      return selectedFilters.includes(item[filterType]);
    });

    const defaultMetrics = [
      "finish__design_powergrid__drop__average__net__VDD__corner__default",
      "finish__design_powergrid__drop__average__net__VSS__corner__default",
      "finish__design_powergrid__drop__worst__net__VDD__corner__default",
      "finish__design_powergrid__drop__worst__net__VSS__corner__default",
      "finish__design_powergrid__voltage__worst__net__VDD__corner__default",
      "finish__design_powergrid__voltage__worst__net__VSS__corner__default",
    ];

    defaultMetrics.forEach((defaultMetric) => {
      if (!dataToExport.find((item) => item.metrics === defaultMetric)) {
        const defaultRow = { metrics: defaultMetric };
        selectedDesigns.forEach((design) => {
          const base = `${design}/${value1}${buildId1}`;
          const test = `${design}/${value2}${buildId2}`;
          defaultRow[base] = "-";
          defaultRow[test] = "-";
        });
        dataToExport.push(defaultRow);
      }
    });

    const fields = [
      "Metrics",
      ...selectedDesigns.flatMap((design) => [
        `${design} Base`,
        `${design} Test`,
        `${design} Comparison`,
      ]),
    ];

    const csvData = [];
    const iterPattern = /__iter__(\d+)/;
    const iterMap = new Map();

    // first pass: add original rows and track the highest iteration for each metric base
    dataToExport.forEach((item) => {
      const row = { Metrics: item.metrics };
      selectedDesigns.forEach((design) => {
        const base = `${design}/${value1}${buildId1}`;
        const test = `${design}/${value2}${buildId2}`;
        const baseValue = parseFloat(item[base]);
        const testValue = parseFloat(item[test]);
        row[`${design} Base`] = isNaN(baseValue) ? "-" : baseValue;
        row[`${design} Test`] = isNaN(testValue) ? "-" : testValue;
        row[`${design} Comparison`] =
          isNaN(baseValue) || isNaN(testValue)
            ? "-"
            : roundToFirstNonZero(testValue - baseValue);
      });
      csvData.push(row);

      // Track the highest iteration number for each metric base
      const match = item.metrics.match(iterPattern);
      if (match) {
        const currentIter = parseInt(match[1], 10);
        const metricBase = item.metrics.split("__iter__")[0];
        if (!iterMap.has(metricBase)) {
          iterMap.set(metricBase, currentIter);
        } else {
          const maxIter = iterMap.get(metricBase);
          if (currentIter > maxIter) {
            iterMap.set(metricBase, currentIter);
          }
        }
      }
    });

    // generate additional rows for metrics with __iter__{n} up to 64
    iterMap.forEach((maxIter, metricBase) => {
      if (maxIter < 64) {
        csvData.push(
          ...generateIterRows(
            metricBase,
            selectedDesigns,
            value1,
            value2,
            buildId1,
            buildId2,
            maxIter
          )
        );
      }
    });

    // sort csvData to ensure metrics and their iterations are in order
    csvData.sort((a, b) =>
      a.Metrics.localeCompare(b.Metrics, undefined, { numeric: true })
    );

    const csv = Papa.unparse(csvData, { columns: fields });
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, filename);
  };

  return (
    <Button style={style} onClick={exportToCSV}>
      Export to CSV
    </Button>
  );
};

export default ExportButton;
