import { createSlice } from "@reduxjs/toolkit";
import { loginUser } from "./authAction";
import jwtDecode from "jwt-decode";

const userToken = localStorage.getItem("userToken")
  ? localStorage.getItem("userToken")
  : null;
const storedState = localStorage.getItem("userInfo");
const userInfo = storedState ? JSON.parse(storedState) : null;

const initialState = {
  loading: false,
  userInfo, // for user object
  userToken, // for storing the JWT
  error: null,
  success: false, // for monitoring the registration process.
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem("userToken"); // deletes token from storage
      state.loading = false;
      state.userInfo = null;
      state.userToken = null;
      state.error = null;
    },
  },
  extraReducers: {
    // register user
    [loginUser.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [loginUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userInfo = payload;
      state.success = true; // login successful
      state.userToken = payload.token;
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;

export const checkTokenExpiration = () => (dispatch) => {
  const token = localStorage.getItem("userToken");

  if (token) {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Convert to seconds
    console.debug("in check exp");

    if (decodedToken.exp < currentTime) {
      // token has expired, log the user out
      dispatch(logout());
    }
  }
};
