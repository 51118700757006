import React, { useEffect, useState } from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
  ResponsiveContainer,
} from "recharts";
import { add, differenceInCalendarDays } from "date-fns";
import { Scatter, Brush, Line, ComposedChart } from "recharts";
import CustomTooltip from "./CustomTooltip";

const colors = [
  "#FF6600",
  "#0088FE",
  "#FF4567",
  "#8884d8",
  "#83a6ed",
  "#8dd1e1",
  "#82ca9d",
  "#a4de6c",
  "#d0ed57",
  "#ffc658",
  "#ffa94d",
  "#ff7c43",
  "#ff6b81",
];

const renderLines = (lines) => {
  return lines.map((line, index) => (
    <Scatter
      key={line.dataKey}
      dataKey={line.dataKey}
      fill={colors[index]}
      line
      shape="circle"
    />
  ));
};

const renderRules = (lines) => {
  return lines.map((line, index) => (
    <Line
      key={"rule" + "/" + line.dataKey}
      dataKey={"rule" + "/" + line.dataKey}
      stroke={colors[index]}
      dot={false}
      strokeWidth={2}
      strokeDasharray="3 3"
      activeDot={false}
      legendType="none"
    />
  ));
};

const renderBrush = (lines) => {
  return lines.map((line, index) => (
    <Brush
      height={30}
      stroke="#8884d8"
      key={line.dataKey}
      dataKey={line.dataKey}
      fill={colors[index]}
      line
      shape="circle"
      background={{ fill: "#eee" }}
    />
  ));
};

const LineChartWithMultiLines = ({
  lines,
  title,
  startDate = new Date(Date.now() - 3 * 30 * 24 * 60 * 60 * 1000),
  endDate = new Date(),
  data,
  rulesData,
  showOverlayChart,
}) => {
  const getTicks = (startDate, endDate, num) => {
    const diffDays = differenceInCalendarDays(endDate, startDate);

    const current = startDate;
    const velocity = Math.round(diffDays / (num - 1));

    const ticks = [startDate?.getTime()];

    for (let i = 1; i < num - 1; i++) {
      ticks.push(add(current, { days: i * velocity }).getTime());
    }

    ticks.push(endDate?.getTime());
    return ticks;
  };
  const [ticks, setTicks] = useState(getTicks(startDate, endDate, 5));
  const [xDomain, setXDomain] = useState([
    () => startDate.getTime(),
    () => endDate.getTime(),
  ]);

  useEffect(() => {
    setTicks(getTicks(startDate, endDate, 5));
    setXDomain([() => startDate.getTime(), () => endDate.getTime()]);
  }, [startDate, endDate]);

  const fillTicksData = (_ticks, data) => {
    const ticks = [..._ticks];
    const filled = [];
    let currentTick = ticks.shift();
    let lastData = null;
    for (const it of data) {
      if (ticks.length && it.date > currentTick && lastData) {
        filled.push({ ...lastData, ...{ date: currentTick } });
        currentTick = ticks.shift();
      } else if (ticks.length && it.date === currentTick) {
        currentTick = ticks.shift();
      }

      filled.push(it);
      lastData = it;
    }

    return filled;
  };

  const [zoomData, setZoomData] = useState(data);

  const handleBrushChange = (domain) => {
    console.debug("domain", domain);
    const newData = data.filter(
      (item) => item.date >= domain?.startIndex && item.date <= domain?.endIndex
    );
    setZoomData(newData);
  };

  const filteredData = data.filter(
    (entry) => entry.date >= startDate && entry.date <= endDate
  );

  return (
    <>
      <span style={{ textAlign: "center" }}>{title}</span>
      <ResponsiveContainer width="100%" height={300}>
        <ComposedChart
          margin={{ top: 5, right: 30, left: 20, bottom: 20 }}
          label={title}
          title={title}
          data={filteredData}
          renderWithCanvas={true}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            scale="time"
            type="number"
            domain={xDomain}
            ticks={ticks}
            tickFormatter={(tick) => new Date(tick).toLocaleDateString()}
          />
          <YAxis tick={{ fontSize: 12 }} padding={{ bottom: 10 }}>
            <Label
              value="Value"
              angle={-90}
              position="insideLeft"
              offset={-15}
            />
          </YAxis>
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          {renderLines(lines)}
          {showOverlayChart && renderRules(lines)}
        </ComposedChart>
      </ResponsiveContainer>
    </>
  );
};

export default LineChartWithMultiLines;
